// Generated by Framer (b5638f1)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/7qT0r3So12155VV5Jq5x/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["xs8xsz5Mc", "PfUHZU7LX", "hpc5IeEpq", "PRJzdlALN"];

const serializationHash = "framer-koS6a"

const variantClassNames = {hpc5IeEpq: "framer-v-2bo5uz", PfUHZU7LX: "framer-v-v8mcm0", PRJzdlALN: "framer-v-1wtruk9", xs8xsz5Mc: "framer-v-15tl2e6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "xs8xsz5Mc", "Variant 2": "PfUHZU7LX", "Variant 3": "hpc5IeEpq", "Variant 4": "PRJzdlALN"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xs8xsz5Mc"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "xs8xsz5Mc", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const isDisplayed = () => {
if (baseVariant === "PRJzdlALN") return false
return true
}

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-15tl2e6", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xs8xsz5Mc"} ref={refBinding} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({hpc5IeEpq: {"data-framer-name": "Variant 3"}, PfUHZU7LX: {"data-framer-name": "Variant 2"}, PRJzdlALN: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1bkandj-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"Nk76Jn2zM-container"} nodeId={"Nk76Jn2zM"} rendersWithMotion scopeId={"olOhjltzP"} style={{opacity: 1}} variants={{hpc5IeEpq: {opacity: 0}}}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"Nk76Jn2zM"} isMixedBorderRadius={false} layoutId={"Nk76Jn2zM"} loop={false} muted objectFit={"cover"} playing={false} poster={"https://framerusercontent.com/images/hvsQQ4ZFnN0gCBMuNOo6AIYEKIY.jpg"} posterEnabled srcFile={"https://framerusercontent.com/assets/roK3TDoms270Y4hR5g06fJUeJ74.mp4"} srcType={"Upload"} srcUrl={"https://framerusercontent.com/assets/MLWPbW1dUQawJLhhun3dBwpgJak.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"} {...addPropertyOverrides({hpc5IeEpq: {playing: true}, PfUHZU7LX: {playing: true}}, baseVariant, gestureVariant)}/></SmartComponentScopedContainer></ComponentViewportProvider>)}</motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-koS6a.framer-1pr6iam, .framer-koS6a .framer-1pr6iam { display: block; }", ".framer-koS6a.framer-15tl2e6 { height: 589px; overflow: visible; position: relative; width: 434px; }", ".framer-koS6a .framer-1bkandj-container { bottom: -5px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 589
 * @framerIntrinsicWidth 434
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"PfUHZU7LX":{"layout":["fixed","fixed"]},"hpc5IeEpq":{"layout":["fixed","fixed"]},"PRJzdlALN":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerolOhjltzP: React.ComponentType<Props> = withCSS(Component, css, "framer-koS6a") as typeof Component;
export default FramerolOhjltzP;

FramerolOhjltzP.displayName = "Video";

FramerolOhjltzP.defaultProps = {height: 589, width: 434};

addPropertyControls(FramerolOhjltzP, {variant: {options: ["xs8xsz5Mc", "PfUHZU7LX", "hpc5IeEpq", "PRJzdlALN"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerolOhjltzP, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})